<template>
  <v-snackbar :color="color" :timeout="timer" v-model="showSnackbar" centered>
    <v-layout align-center justify-space-around>
      <v-icon class="pr-3" dark>{{ icon }}</v-icon>
      <v-layout column>
        <div class="text-subtitle-1 font-weight-bold">
          {{ message }}
        </div>
      </v-layout>
    </v-layout>
  </v-snackbar>
</template>

<script>
export default {
  name: "toast",
  data() {
    return {
      showSnackbar: false,
      message: "",
      color: "success",
      icon: "mdi-check-circle",
      timer: 5000,
    };
  },
  methods: {
    show(data) {
      this.message = data.message ?? 'missing "message".';
      this.color = data.color ?? "success";
      this.timer = data.timer ?? 5000;
      this.icon = data.icon ?? "mdi-check-circle";
      this.showSnackbar = true;
    },
  },
};
</script>
