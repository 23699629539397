import colors from "vuetify/lib/util/colors";

const state = {
  originalInventory: [],
  reconcile: [],
  workOrderProducts: [],
  status: [
    {
      text: "complete",
      value: 1,
      color: colors.green.base,
    },
    {
      text: "in testing",
      inTesting: true,
      color: colors.grey.base,
    },
    {
      text: "in progress",
      value: 0,
      color: colors.blue.base,
    },
    {
      text: "cancelled",
      value: -1,
      color: colors.red.base,
    },
  ],
  transferTypes: [
    { text: "Assembled Units", value: "assembled" },
    { text: "Product Components", value: "components" },
    { text: "Raw Materials", value: "rawMaterials" },
  ],
  transferStatus: [
    { text: "Cancelled", value: "cancelled", color: colors.red.base },
    { text: "In-Progress", value: "in progress", color: colors.blue.base },
    { text: "Complete", value: "complete", color: colors.green.base },
  ],
  plantStatus: [
    {
      text: "Planted",
      value: "planted",
      description: "",
      icon: "mdi-seed",
      active: true,
      room: true,
      color: colors.brown.base,
    },
    {
      text: "Ready to harvest",
      value: "readyToHarvest",
      description: "",
      icon: "mdi-barley",
      active: true,
      room: true,
      harvest: false,
      color: colors.green.base,
    },
    {
      text: "Drying",
      value: "drying",
      description: "",
      icon: "mdi-hair-dryer",
      active: true,
      room: false,
      harvest: true,
      color: colors.grey.base,
    },
    {
      text: "Trimming",
      value: "trimming",
      description: "",
      icon: "mdi-content-cut",
      active: true,
      room: false,
      harvest: true,
      color: colors.grey.base,
    },
    {
      text: "Curing",
      value: "curing",
      description: "",
      icon: "mdi-thermometer-check",
      active: true,
      room: false,
      harvest: true,
      color: colors.grey.base,
    },
    {
      text: "Quarantined",
      value: "quarantined",
      description: "",
      icon: "mdi-biohazard",
      active: true,
      room: false,
      harvest: true,
      color: colors.orange.base,
    },
    {
      text: "Harvested",
      value: "harvested",
      description: "",
      icon: "mdi-content-cut",
      active: false,
      room: false,
      harvest: true,
      color: colors.grey.base,
    },
    {
      text: "Dead",
      value: "dead",
      description: "",
      icon: "mdi-skull",
      active: false,
      harvest: true,
      color: colors.shades.black,
    },
  ],
  harvestStatus: [
    { text: "Cancelled", value: "cancelled", color: colors.red.base },
    { text: "In-Progress", value: "in progress", color: colors.blue.base },
    { text: "Ready for Review", value: "readyForReview", color: colors.blueGrey.base },
    { text: "Complete", value: "complete", color: colors.green.base },
  ],
};

const actions = {
  _originalReconcileInventory({ commit }, payload) {
    commit("setOriginalReconcileInventory", payload);
  },
  _reconcileInventory({ commit }, payload) {
    commit("setReconcileInventory", payload);
  },
  _workOrderProducts({ commit }, payload) {
    commit("setWorkOrderProducts", payload);
  },
};

const mutations = {
  setOriginalReconcileInventory(state, payload) {
    state.originalInventory = payload;
  },
  setReconcileInventory(state, payload) {
    state.reconcile = payload;
  },
  setWorkOrderProducts(state, payload) {
    state.workOrderProducts = payload;
  },
};

const getters = {
  getWorkOrderStatus(state) {
    return state.status;
  },
  getOriginalReconcileInventory(state) {
    return state.originalInventory;
  },
  getReconcileInventory(state) {
    return state.reconcile;
  },
  getWorkOrderProducts(state) {
    return state.workOrderProducts;
  },
  getTransferTypes(state) {
    return state.transferTypes;
  },
  getTransferStatus(state) {
    return state.transferStatus;
  },
  getPlantStatus(state) {
    return state.plantStatus;
  },
  getHarvestStatus(state) {
    return state.harvestStatus;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
