var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"flat":""},model:{value:(_vm.payload.panels.expenses),callback:function ($$v) {_vm.$set(_vm.payload.panels, "expenses", $$v)},expression:"payload.panels.expenses"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-toolbar-title',{staticClass:"text-capitalize"},[_vm._v("line items")])],1),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.response.headers.expenses,"loading-text":"Loading Line Items...","items":_vm.getRightDrawer.data.expenses,"no-data-text":"No Line Items added","sort-by":"name","group-by":"state","hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"group.header",fn:function({
                  group,
                  items,
                  isOpen,
                  toggle,
                  remove,
                  headers,
                }){return [_c('GroupByHeaderRow',{attrs:{"headers":headers,"group":group,"items":items,"isOpen":isOpen,"showRemoveIcon":false},on:{"toggle":toggle,"remove":remove}})]}},{key:"item.number",fn:function({ index }){return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"item.name",fn:function({ item }){return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.product.name))])]}},{key:"item.state",fn:function({ item }){return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.state))])]}},{key:"item.location",fn:function({ item }){return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.location))])]}},{key:"item.amount",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.amount)))])]}},{key:"item.qty",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("addComma")(item.qty)))])]}},{key:"item.total",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm._f("currency")((item.amount * item.qty))))])]}},{key:"item.received",fn:function({ item }){return [(item.received)?_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.themes.dark.success}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.submit))]):_c('span',{staticClass:"text--disabled"},[_vm._v("-")])]}}])})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Brand","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("capitalize")(_vm.getRightDrawer.data.brand.name),"hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Vendor","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("capitalize")(_vm.getRightDrawer.data.vendor),"hide-details":"","readonly":""}})],1),(_vm.getRightDrawer.data.relatedID)?_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Project","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.relatedID
            ? 'Click to view'
            : 'No associated Project',"hide-details":"","readonly":""}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-textarea',{attrs:{"label":"Notes","outlined":"","background-color":"grey lighten-3","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.notes,"hide-details":"","readonly":"","disabled":_vm.getRightDrawer.data.notes.length === 0}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Ship Date","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.date.ship
            ? _vm.moment(_vm.getRightDrawer.data.date.ship.seconds * 1000).format(
                'MM/DD/YYYY'
              )
            : '',"hide-details":"","readonly":"","disabled":!_vm.getRightDrawer.data.date.ship}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Received Date","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.date.received
            ? _vm.moment(_vm.getRightDrawer.data.date.received.seconds * 1000).format(
                'MM/DD/YYYY'
              )
            : '',"hide-details":"","readonly":"","disabled":!_vm.getRightDrawer.data.date.received}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Sub-total","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("currency")(_vm.getRightDrawer.data.expenses
            .map((expense) => expense.amount * expense.qty)
            .reduce((a, b) => a + b, 0)),"hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Sales Tax","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("currency")(_vm.getRightDrawer.data.balance.salesTax),"hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Shipping","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("currency")(_vm.getRightDrawer.data.balance.shipping),"hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Balance Due","color":_vm.$vuetify.theme.themes.dark.primary,"background-color":_vm.getRightDrawer.data.balance.due === 0 ? '' : 'grey lighten-3',"value":_vm._f("currency")((_vm.getRightDrawer.data.balance.shipping +
            _vm.getRightDrawer.data.balance.salesTax +
            _vm.getRightDrawer.data.expenses
              .map((expense) => expense.amount * expense.qty)
              .reduce((a, b) => a + b, 0) -
            _vm.getRightDrawer.data.deposits
              .map((deposit) => deposit.amount)
              .reduce((a, b) => a + b, 0))),"hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Total","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("currency")((_vm.getRightDrawer.data.balance.shipping +
            _vm.getRightDrawer.data.balance.salesTax +
            _vm.getRightDrawer.data.expenses
              .map((expense) => expense.amount * expense.qty)
              .reduce((a, b) => a + b, 0))),"hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Internal Invoice #","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.number.internal.toUpperCase(),"hide-details":"","readonly":"","disabled":_vm.getRightDrawer.data.number.internal.length === 0}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"External Invoice #","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm.getRightDrawer.data.number.external.toUpperCase(),"disabled":_vm.getRightDrawer.data.number.external.length === 0,"hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Created","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("moment")(_vm.getRightDrawer.data.created,'MM/DD/YYYY @ hh:mm A'),"hint":_vm._f("capitalize")(`created by: ${_vm.getRightDrawer.data.createdBy.name.first} ${_vm.getRightDrawer.data.createdBy.name.last}`),"persistent-hint":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Updated","color":_vm.$vuetify.theme.themes.dark.primary,"value":_vm._f("moment")(_vm.getRightDrawer.data.updated,'MM/DD/YYYY @ hh:mm A'),"hint":_vm._f("capitalize")(`last updated: ${_vm.getRightDrawer.data.lastUpdated.name.first} ${_vm.getRightDrawer.data.lastUpdated.name.last}`),"persistent-hint":"","readonly":""}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }