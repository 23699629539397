<template>
  <v-container fluid>
    <template v-if="!loading.overall">
      <!-- Content -->
      <v-container fluid class="pa-0 rounded-lg">
        <v-toolbar v-if="payload.filters.step > 1" flat color="transparent">
          <v-btn
            small
            rounded
            :style="`background: ${$vuetify.theme.themes.dark.primary}; color: ${$vuetify.theme.themes.dark.secondary}`"
            @click="payload.filters.step--"
          >
            <v-icon>{{ $vuetify.icons.values.back }}</v-icon>
            <span>back</span>
          </v-btn>
          <v-spacer />
          <v-btn
            small
            rounded
            :style="`background: ${$vuetify.theme.themes.dark.review}; color: ${$vuetify.theme.themes.dark.secondary}`"
            @click="traverse('home')"
          >
            <v-icon>mdi-home</v-icon>
            <span>home</span>
          </v-btn>
        </v-toolbar>
        <template v-if="payload.filters.step === 1">
          <span>Select the module below to see a list of related guides</span>
        </template>
        <!-- Modules -->
        <template v-if="payload.filters.step === 1">
          <v-list class="py-0">
            <v-list-item-group>
              <template v-for="(phase, index) in getPhases">
                <v-list-item three-line @click="traverse('phase', phase)">
                  <v-list-item-icon class="mr-0">
                    <v-icon large class="mr-1">{{ phase.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="text-wrap font-weight-medium text-capitalize"
                        >{{ phase.text }}
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span>{{ phase.desc }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>{{ $vuetify.icons.values.next }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </template>
        <!-- Guides -->
        <template v-if="payload.filters.step === 2">
          <!-- Guide repo -->
          <template v-if="guides.length > 0">
            <v-list class="py-0">
              <v-list-item-group>
                <template v-for="(guide, index) in guides">
                  <v-list-item three-line @click="traverse('guide', guide)">
                    <v-list-item-icon class="mr-0">
                      <v-icon class="mr-1" :color="guide.color">{{
                        guide.icon
                      }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="text-wrap font-weight-medium"
                          >{{ guide.text }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span>{{ guide.desc }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>{{ $vuetify.icons.values.next }}</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </template>
          <!-- No Guides -->
          <template v-else>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-center">
                  <span class="text--disabled">No guides for this module.</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
        <!-- Instructions -->
        <template v-if="payload.filters.step === 3">
          <span>{{ selectedGuide }}</span>
          <v-list class="py-0">
            <v-list-item-group>
              <template v-for="(instruction, index) in instructions">
                <v-list-item>
                  <v-list-item-action>
                    <span>#{{ index + 1 }}</span>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="text-wrap" v-html="instruction.text"> </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="instruction.action">
                    <v-btn
                      small
                      rounded
                      :style="`background: ${instruction.action.color}; color: ${$vuetify.theme.themes.dark.secondary};`"
                      @click="traverse('instruction-view', instruction)"
                      :disabled="instruction.action.disabled"
                    >
                      <v-icon
                        v-if="instruction.action.iconPosition === 'left'"
                        >{{ instruction.action.icon }}</v-icon
                      >
                      <span :class="`${instruction.action.margin}`">{{
                        instruction.action.label
                      }}</span>
                      <v-icon
                        v-if="instruction.action.iconPosition === 'right'"
                        >{{ instruction.action.icon }}</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </template>
      </v-container>
    </template>
    <template v-else>
      <v-skeleton-loader type="actions, divider, card, table" />
    </template>
  </v-container>
</template>

<script>
// Libraries
import { mapGetters } from "vuex";
// Components

export default {
  name: "helpdesk-view",
  data: () => ({
    loading: {
      overall: false,
    },
    response: {
      // guides: [
      //   // Admin
      //   {
      //     text: "What do the colors mean in mytrace?",
      //     desc: "Based on the status of a view, the colors in mytrace will change (cannot submit = blue, ready to submit = black).",
      //     value: "systemColors",
      //     icon: "mdi-magnify",
      //     color: "black",
      //     phase: 0,
      //   },
      //   // Sourcing
      //   {
      //     text: "Add a retail brand",
      //     desc: "Add a new brand",
      //     value: "addRetailBrand",
      //     icon: "mdi-store",
      //     color: "black",
      //     phase: 1,
      //   },
      //   {
      //     text: "Add a product category",
      //     desc: "Add a product category to a brand",
      //     value: "addProductCategory",
      //     icon: "mdi-sitemap",
      //     color: "black",
      //     phase: 1,
      //   },
      //   {
      //     text: "Add a products to a product category",
      //     desc: "Add new products to an existing product category",
      //     value: "addProducts",
      //     icon: "mdi-package-variant",
      //     color: "black",
      //     phase: 1,
      //   },
      //   {
      //     text: "Create a new location",
      //     desc: "Create a new warehouse location",
      //     value: "addLocation",
      //     icon: "mdi-warehouse",
      //     color: "black",
      //     phase: 1,
      //   },
      //   {
      //     text: "Create a vendor order",
      //     desc: "Create a new purchase order for product components",
      //     value: "addVendorOrder",
      //     icon: "mdi-truck",
      //     color: "black",
      //     phase: 1,
      //   },
      //   {
      //     text: "Update a vendor order",
      //     desc: "Mark product components as received, add payments/tracking numbers",
      //     value: "updateVendorOrder",
      //     icon: "mdi-pencil",
      //     color: "black",
      //     phase: 1,
      //   },
      //   // Manufacturing
      //   // Sales
      //   {
      //     text: "Add an account",
      //     desc: "Add a new lead/account to mytrace",
      //     value: "addSalesAccount",
      //     icon: "mdi-folder-account",
      //     color: "black",
      //     phase: 3,
      //   },
      //   {
      //     text: "Update an account",
      //     desc: "Modify an account's information (Name/Address/Status/Managed by...)",
      //     value: "updateSalesAccount",
      //     icon: "mdi-pencil",
      //     color: "black",
      //     phase: 3,
      //   },
      //   {
      //     text: "Add contacts to an account",
      //     desc: "Add contacts (owner/general manager) to an account",
      //     value: "addSalesContact",
      //     icon: "mdi-badge-account-horizontal",
      //     color: "black",
      //     phase: 3,
      //   },
      //   {
      //     text: "Add tasks to an account",
      //     desc: "Add tasks (Calls/Meetings...) to an account",
      //     value: "addSalesTasks",
      //     icon: "mdi-calendar-check",
      //     color: "black",
      //     phase: 3,
      //   },
      //   {
      //     text: "Create a sales order",
      //     desc: "Create a new order (sales/sample) for an account",
      //     value: "newSalesOrder",
      //     icon: "mdi-clipboard-list",
      //     color: "black",
      //     phase: 3,
      //   },
      //   {
      //     text: "Sales Order - Process",
      //     desc: "How are the sales orders organized in mytrace? What do the statuses mean?",
      //     value: "salesOrderProcess",
      //     icon: "mdi-help-circle",
      //     color: "black",
      //     phase: 3,
      //   },
      //   // Support
      //   {
      //     text: "Create a new store event",
      //     desc: "Create a new store event (in-person/ghost/brand presence) for an account",
      //     value: "newStoreEvent",
      //     icon: "mdi-calendar-multiple",
      //     color: "black",
      //     phase: 4,
      //   },
      //   {
      //     text: "Create a new training",
      //     desc: "Create a new training (in-person/offline) for an account",
      //     value: "newTraining",
      //     icon: "mdi-school",
      //     color: "black",
      //     phase: 4,
      //   },
      // ],
      // instructions: ,
    },
    payload: {
      filters: {
        step: 1,
        phase: null,
        guide: null,
      },
    },
  }),
  created() {
    this.setup();
  },
  methods: {
    /* API Calls */
    // GET
    // POST
    /* Main */
    async setup() {
      this.loading.overall = true;
      this.loading.overall = false;
    },
    traverse(action, params) {
      switch (action) {
        case "phase":
          this.payload.filters.phase = params.phase;
          this.payload.filters.step++;
          break;
        case "guide":
          this.payload.filters.guide = params.value;
          this.payload.filters.step++;
          break;
        case "instruction-view":
          if (params.action.link.length > 0) {
            this.$router.push({
              name: params.action.link,
            });
          }
          break;
        case "home":
          this.payload.filters.step = 1;
          this.payload.filters.phase = this.payload.filters.guide = null;
          break;
      }
    },
    /* Misc */
  },
  computed: {
    ...mapGetters([
      // System
      "getAppName",
      "getUser",
      // Phases+Views
      "getPhases",
      "getPhaseViews",
      // Helpdesk
      "getHelpdeskGuides",
      "getHelpdeskInstructions",
    ]),
    phaseSet() {
      return this.payload.filters.phase ? true : false;
    },
    guideSet() {
      return this.payload.filters.guide ? true : false;
    },
    selectedPhase() {
      return this.phaseSet
        ? this.getPhases.find(
            (option) => option.phase === this.payload.filters.phase
          ).text
        : "-";
    },
    selectedGuide() {
      return this.guideSet
        ? this.getHelpdeskGuides.filter(
            (guide) => guide.value === this.payload.filters.guide
          ).text
        : "-";
    },
    guides() {
      return this.getHelpdeskGuides.filter(
        (guide) => guide.phase === this.payload.filters.phase
      );
    },
    instructions() {
      return this.getHelpdeskInstructions
        .filter(
          (instruction) => instruction.related === this.payload.filters.guide
        )
        .sort((a, b) => (a.sort > b.sort ? 1 : -1));
    },
  },
};
</script>

<style></style>
