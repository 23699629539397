import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// Fonts
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { colors } from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
    values: {
      global: "mdi-earth",
      add: "mdi-plus",
      submit: "mdi-check",
      cancel: "mdi-close-thick",
      back: "mdi-chevron-left",
      next: "mdi-chevron-right",
      update: "mdi-pencil",
      view: "mdi-eye",
      newTab: "mdi-open-in-new",
      search: "mdi-magnify",
      query: "mdi-cloud-search",
      filter: "mdi-filter-multiple",
      undo: "mdi-undo",
      review: "mdi-help-circle",
      lock: "mdi-lock-open",
      file: "mdi-file",
      emailTemplates: "mdi-email-newsletter",
      email: "mdi-email",
      phoneNumber: "mdi-phone",
      contact: "mdi-badge-account-horizontal",
      contacts: "mdi-contacts",
      money: "mdi-cash",
      ledger: "mdi-calendar-text",
      country: "mdi-earth",
      state: "mdi-map-marker",
      tasks: "mdi-file-tree",
      task: "mdi-calendar-check",
      users: "mdi-account-group",
      user: "mdi-account",
      vendors: "mdi-package-variant-closed-check",
      retailBrand: "mdi-store",
      vendorOrder: "mdi-truck",
      workOrder: "mdi-beaker",
      order: "mdi-clipboard-list",
      onboarding: "mdi-timeline-check",
      leadPool: "mdi-account-search",
      parentAccount: "mdi-domain",
      account: "mdi-folder-account",
      childAccount: "mdi-account-child",
      whiteLabel: "mdi-package",
      location: "mdi-warehouse",
      eventStaffing: "mdi-briefcase-account",
      storeEvent: "mdi-calendar-multiple",
      mixCalculator: "mdi-tune-vertical",
      rawMaterial: "mdi-cannabis",
      rawMaterialVendor: "mdi-power-plug",
      terpene: "mdi-water",
      budtenderTraining: "mdi-school",
      promotion: "mdi-sale",
      supportTicket: "mdi-briefcase",
      testingLab: "mdi-test-tube",
      transfer: "mdi-transfer",
      shipping: "mdi-package-variant-closed",
      productCategory: "mdi-sitemap",
      products: "mdi-package-variant",
      coa: "mdi-newspaper",
      ar: "mdi-hand-coin",
      map: "mdi-map-marker-radius",
      email: "mdi-email",
      expenseTypes: "mdi-file-sign",
      expenseVendors: "mdi-card-account-details",
      expenses: "mdi-account-cash",
      projects: "mdi-chart-gantt",
      projectTemplates: "mdi-format-list-group-plus",
      cursor: "mdi-cursor-default",
      notification: "mdi-bell",
      actionList: "mdi-list-status",
      socials: "mdi-account-voice",
      alerts: "mdi-timer",
      alertNew: "mdi-timer-plus",
      room: "mdi-floor-plan",
      plant: "mdi-tree",
      qrTemplates: "mdi-qrcode",
    },
  },
  theme: {
    defaultTheme: "dark",
    themes: {
      dark: {
        primary: colors.shades.black,
        secondary: colors.shades.white,
        bg: colors.grey.lighten3,
        review: colors.blue.base,
        paused: colors.deepPurple.base,
        success: colors.green.base,
        error: colors.red.base,
        update: colors.orange.base,
        contacts: colors.deepPurple.base,
        money: colors.green.darken3,
        shipping: colors.purple.base,
        orders: colors.orange.darken2,
        products: colors.brown.base,
        ledger: colors.grey.base,
        promotion: colors.blueGrey.darken3,
        promoUnit: colors.lightBlue.darken3,
        overpaymentCredit: colors.blueGrey.base,
        colors,
      },
    },
  },
});
